// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-legal-page-js": () => import("/opt/build/repo/src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-blog-page-js": () => import("/opt/build/repo/src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-tag-page-js": () => import("/opt/build/repo/src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-index-js": () => import("/opt/build/repo/src/pages/mobile/index.js" /* webpackChunkName: "component---src-pages-mobile-index-js" */),
  "component---src-pages-parents-index-js": () => import("/opt/build/repo/src/pages/parents/index.js" /* webpackChunkName: "component---src-pages-parents-index-js" */),
  "component---src-pages-research-index-js": () => import("/opt/build/repo/src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-schools-index-js": () => import("/opt/build/repo/src/pages/schools/index.js" /* webpackChunkName: "component---src-pages-schools-index-js" */),
  "component---src-pages-students-index-js": () => import("/opt/build/repo/src/pages/students/index.js" /* webpackChunkName: "component---src-pages-students-index-js" */),
  "component---src-pages-teachers-index-js": () => import("/opt/build/repo/src/pages/teachers/index.js" /* webpackChunkName: "component---src-pages-teachers-index-js" */)
}

